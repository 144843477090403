.ScoreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: max-content;
  height: 100vh;
}

.ScoreContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 75vw;
  height: 70%;
  min-height: 70vh;
  padding: 5vw;
  background-color: rgba(255, 255, 255, 0.356);
  border-radius: 20px;
  text-align: center;
  font-size: calc(0.5rem + 0.6vw);
  z-index: 2000;
}

.ScoreContent h2 {
  margin: 0;
}

.ScoreContent::before {
  z-index: -1;
  position: absolute;
  content: "";
  width: 75vw;
  height: 70vh;
  background-image: url('');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: brightness(0);
}

.ScorePlaces {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex: 2;
}

.ScorePlace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 200px;
  width: 30vw;
  margin: 0% 3%;
}

.ScorePlace img {
  width: 80%;
  max-height: 30vh;
  max-width: 30vh;
}

.ScorePlace div {
  padding: 2%;
  height: 96%;
  width: 100%;
}

.secondPlace div {
  background-color: silver;
  background: linear-gradient(90deg, #b8b7b7 0%, #a3a3a5 75%);
}

.firstPlace div {
  background-color: gold;
  background: linear-gradient(90deg, #ffc655 0%, #f0b803 75%);
}

.thirdPlace div {
  background-color: chocolate;
  background: linear-gradient(90deg, #6b3916 0%, #a66717 75%);
}

.secondPlace, .thirdPlace {
  height: 80%;
}

.firstPlace {
  height: 100%;
}

.ScoreBackButton {
  margin-top: 2vh;
  border: 1px solid rgb(178, 116, 230);
  padding: 2vh 3.5vh;
  font-size: 2.5vh;
}

.ScoreBackButton:hover{ 
  color: white;
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

.ScoreImgFooter {
  position: fixed;
  max-height: 12vh;
  top: 86vh;
  right: 1vw;
  max-width: 17vw;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .ScoreContainer {
    padding-top: 10vh;
    min-height: 90vh;
    justify-content: flex-start;
  }
  .ScoreContent::before {
    position: absolute;
    content: "";
    width: 75vw;
    height: 70vh;
    background-image: url('');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .ScoreContent {
    width: 92vw;
    font-size: 2.5vh;
    padding: 1vw;
  }
  .ScoreImgFooter {
    position: fixed;
    max-height: 10vh;
    top: 90vh;
    right: 1vw;
    max-width: 17vw;
  }
}