.WelcomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
}

.WelcomeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
  min-height: 70vh;
  padding: 5vw;
  background-color: rgba(255, 255, 255, 0.356);
  border-radius: 20px;
  text-align: center;
  font-size: 3vh;
  z-index: 2000;
}

.WelcomeContent::before {
  z-index: -1;
  position: absolute;
  content: "";
  width: 75vw;
  height: 70vh;
  background-image: url('../../../media/BackgroundLogo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: brightness(0);
}

.WelcomeButton {
  border: 0px;
  background-color: rgba(255, 255, 255, 0.048);
  color: white;
  box-shadow: rgb(255, 255, 255) 0px 0px 2px;
  padding: 2vh 6vh;
  margin-top: 5vh;
  font-size: 2.5vh;
}

.WelcomeButton:hover {
  background-color: rgba(252, 252, 252, 0.281);
}

.WelcomeImgFooter {
  position: fixed;
  max-height: 12vh;
  top: 86vh;
  right: 1vw;
  max-width: 17vw;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .WelcomeContainer {
    padding-top: 10vh;
    min-height: 90vh;
    justify-content: flex-start;
  }
  .WelcomeContent::before {
    position: absolute;
    content: "";
    width: 75vw;
    height: 70vh;
    background-image: url('../../../media/BackgroundLogo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .WelcomeContent {
    width: 95vw;
    font-size: 2.5vh;
    padding: 1vw;
  }
  .WelcomeImgFooter {
    position: fixed;
    max-height: 10vh;
    top: 90vh;
    right: 1vw;
    max-width: 17vw;
  }
}