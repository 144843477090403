.answersContainer {
  width: 100vw;
  height: 100vh;

  background-image: url('../../../media/BackgroundLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;

  display: flex;
  flex-direction: column;
}

.answersContainer .ansTitle {
  margin: 20px auto 0px 2vw;
  font-size: 1.5vw;
  color: #662d91;
}
.answersContainer .ansSub {
  margin: 0px auto 20px 2vw;
  font-size: 1.5vw;
  color: #662d91;
}
.answersContainer .ansSub span {
  font-size: 1.5vw;
  color: #1cb64f;
}

.answersContainer .contentCards {
  width: 95%;
  max-height: 90vh;

  margin: 10px auto;

  scrollbar-width: thin;
  scrollbar-color: #662d91 transparent;

  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.answersContainer .contentCards::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.answersContainer .contentCards::-webkit-scrollbar-track {
  background: transparent;
}
.answersContainer .contentCards::-webkit-scrollbar-thumb {
  background: #662d91;
}
.answersContainer .contentCards::-webkit-scrollbar-thumb:hover {
  background: #370e57;
}

.answersContainer .contentCards .card {
  width: 80%;

  margin: 10px auto;
  padding: 10px;

  display: flex;
  flex-flow: column;

  background-color: #FFF;
  box-shadow: 3px 3px 8px #662d9177;
  border-radius: 20px;
}

.answersContainer .contentCards .card .userCont {
  width: 90%;

  margin: 20px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answersContainer .contentCards .card .userCont .userInfo {
  margin: auto;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.answersContainer .contentCards .card .userCont .userInfo h2 {
  margin: auto;

  text-align: center;
  color: #662d91;
  font-size: 1.5vw;
}
.answersContainer .contentCards .card .userCont .userInfo h3 {
  margin: auto;

  text-align: center;
  color: #662d91;
  font-size: 1vw;
}

/* --------------------------------------- */

.answersContainer .contentCards .card .userCont .dateCont {
  width: max-content;
  margin: auto;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.answersContainer .contentCards .card .userCont .dateCont h3 {
  width: 100%;

  margin: auto;

  text-align: end;
  color: #999;
  font-size: 1vw;
}

/* ====================================== */

.answersContainer .contentCards .card .QA {
  width: 100%;

  margin: 10px auto;

  border-top: 1px solid #662d91;
}

.answersContainer .contentCards .card .QA .questionCont {
  width: 95%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.answersContainer .contentCards .card .QA .questionCont .nomenclatura {
  width: 90%;

  margin: 10px auto;

  font-size: 1.5vw;
  line-height: 1.5vw;
  font-weight: 600;
  text-align: center;
}

.answersContainer .contentCards .card .QA .questionCont .question {
  width: 90%;

  margin: auto;

  font-size: 1.5vw;
  line-height: 1.5vw;
  font-weight: 300;
  text-align: center;
}

.answersContainer .contentCards .card .QA .questionCont .ansCont {
  width: 90%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-x: auto;
  overflow-y: hidden;
}
.answersContainer .contentCards .card .QA .questionCont .ansCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.answersContainer .contentCards .card .QA .questionCont .ansCont::-webkit-scrollbar-track {
  background: transparent;
}
.answersContainer .contentCards .card .QA .questionCont .ansCont::-webkit-scrollbar-thumb {
  background: #662d91;
}
.answersContainer .contentCards .card .QA .questionCont .ansCont::-webkit-scrollbar-thumb:hover {
  background: #370e57;
}

.answersContainer .contentCards .card .QA .questionCont .ansCont h2 {
  margin: 20px;
  padding: 10px;
  font-size: 1vw;
  text-align: center;
  border-radius: 10px;
}

.answersContainer .contentCards .card .QA .questionCont .ansCont .correcta {
  color: #1cb64f;
  border: 1px solid #1cb64f;
}

.answersContainer .contentCards .card .QA .questionCont .ansCont .incorrecta {
  color: #FBB;
  border: 1px solid #FBB;
}

.answersContainer .contentCards .card .QA .questionCont .ansCont .generic {
  color: #BBB;
  border: 1px solid #BBB;
}

.answersContainer .contentCards .card .scoreData {
  width: 100%;

  margin: auto;

  border-top: 1px solid #662d91;

  display: flex;
}

.answersContainer .contentCards .card .scoreData h2 {
  width: 100%;

  margin: 20px auto;

  text-align: center;
  font-size: 1.5vw;
}

.answersContainer .contentCards .card .scoreData .green {
  color: #1cb64f;
}

.answersContainer .contentCards .card .scoreData .bad {
  color: #CCC;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media screen and (max-width: 1024px) {

}
