.FinishContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #006dbd;
}

.FinishContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
  min-height: 70vh;
  padding: 4vw;
  background-color: rgba(255, 255, 255, 0.356);
  border-radius: 20px;
  text-align: center;
  font-size: 3vh;
}

.FinishContent p {
  margin: 0px;
}

.FinishContent h3 {
  margin-bottom: 10vh;
}

.FinishVote {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: solid 2px #ffffff;
  min-width: 50%;
  padding: 4px;
  margin-bottom: 10vh;
}

.FinishVote img {
  max-height: 5vh;
  cursor: pointer;
}

.FinishButton {
  border: 0px;
  background-color: rgba(255, 255, 255, 0.048);
  color: white;
  box-shadow: rgb(255, 255, 255) 0px 0px 2px;
  padding: 2vh 6vh;
  margin-top: 5vh;
  font-size: 2.5vh;
}

.FinishButton:hover {
  background-color: rgba(252, 252, 252, 0.281);
}

.FinishImgFooter {
  position: fixed;
  max-height: 12vh;
  top: 86vh;
  right: 1vw;
  max-width: 17vw;
}

@media all and (max-width: 800), (orientation: portrait) {
  .FinishContainer {
    padding-top: 10vh;
    min-height: 90vh;
    justify-content: flex-start;
  }
  .FinishContent {
    width: 95vw;
    font-size: 2.5vh;
    padding: 1vw;
  }
  .FinishVote {
    min-width: 80%;
  }
  .FinishImgFooter {
    position: fixed;
    max-height: 10vh;
    top: 90vh;
    right: 1vw;
    max-width: 17vw;
  }
}