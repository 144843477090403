.ProfileContainer {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../media/BackgroundLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProfileHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
}

.ProfileHeader img {
  height: 6vh;
  margin-left: 5vw;
}

.ProfileHeaderRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
}

.ProfileHeaderRight img {
  margin-left: 0vw;
}

.ProfileContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.ProfileOption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProfileOption img {
  max-height: 15vh;
  margin-bottom: 2vh;
  cursor: pointer;
}

.ProfileOption label {
  font-size: 2vh;
}

.ProfileFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
}

.ProfileFooter img {
  height: 6vh;
  margin-right: 5vw;
}

.ProfileDataContent {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  border: 4px solid rgb(112,52,187);
  padding: 4vh;
}

.ProfileDataContent button {
  margin-top: 2vh;
  font-size: 2vh;
  border: 0;
  background-color: transparent;
  padding: 0;
}

@media (orientation: portrait) {
  .ProfileContent {
    flex-direction: column;
    min-height: 65vh;
  }
  .ProfileOption {
    margin-bottom: 2vh;
  }
  .ProfileOption img {
    max-height: 10vh;
    margin-bottom: 2vh;
  }
}