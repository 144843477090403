.questionEditContainer {
  width: 100vw;
  height: 100vh;

  background: rgb(112,52,187);
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(39,156,239,1) 100%);

  display: flex;
  flex-flow: column;

  /* overflow: hidden; */
}

.questionEditContainer header {
  width: 100%;
  height: 10vh;
  padding: 2vh;
  box-sizing: border-box;

  display: flex;
}

.questionEditContainer header img {
  height: 9vh;
}

.questionEditContainer footer {
  width: 100%;
  height: 70px;

  margin: auto auto 0 auto;

  display: flex;
}

.questionEditContainer footer .ozelLogo {
  width: 40px;
  height: 40px;

  margin: auto 1% auto auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../images/logoOzelBlanco.png');
}

.questionEditContainer footer h2 {
  margin: auto 2% auto 0%;
  color: #FFF;
}

/* ------------------------------------------- */
/* ------------------------------------------- */
/* ------------------------------------------- */

.questionEditContainer .body {
  width: 98vw;
  height: 100%;

  margin: auto;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #291044 transparent;

  display: flex;
}

.questionEditContainer .body::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.questionEditContainer .body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.questionEditContainer .body::-webkit-scrollbar-thumb {
  background: #291044;
  border-radius: 10px;
}
.questionEditContainer .body::-webkit-scrollbar-thumb:hover {
  background: rgb(112,52,187);
}

/* ------------------------------------------- */

.questionEditContainer .body .leftPanel {
  width: 10%;
  height: 90%;
  min-width: 100px;

  background-color: #FFF;
  border-radius: 20px;

  margin: 20px auto;

  display: flex;
}

/* ------------------------------------------- */

.questionEditContainer .body .centerPanel {
  width: 80%;

  border-radius: 20px;

  margin: 20px auto;

  display: flex;
  flex-flow: column;
}

.questionEditContainer .body .centerPanel .imgContainer {
  width: 80%;
  max-height: 35vh;

  margin: auto auto 10px auto;

  display: flex;
  flex-flow: column;

  background-color: rgb(109, 99, 99);
  border-radius: 20px;
    display: none;
}

.questionEditContainer .body .centerPanel .imgContainer .prevImg {
  width: 20vw;
  height: 20vw;

  margin: auto;

  display: flex;
  flex-flow: column;

  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.questionEditContainer .body .centerPanel .imgContainer video {
  width: 30vw;
  height: 20vw;

  margin: 10px auto;

  background-color: #000;
  border-radius: 20px;
}

.questionEditContainer .body .centerPanel .imgContainer .iframeCont {
  width: 30vw;
  height: 20vw;

  margin: 10px auto;

  display: flex;

}



.questionEditContainer .body .centerPanel .imgContainer .iframeCont iframe {
  width: 100%;
  height: 100%;

  margin: auto;

  border-radius: 20px;
}

/* -------------------------------------------------------------------- */

.questionEditContainer .body .centerPanel .imgContainer .actions {
  width: 100%;

  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}



.questionEditContainer .body .centerPanel .imgContainer .actions .inputContainer {
  width: 50%;

  margin: auto auto auto 10px;

  display: flex;
}

.questionEditContainer .body .centerPanel .imgContainer .actions .inputContainer .inputIframe {
  width: 100%;

  border: none;
  outline: none;
  background-color: transparent;

  margin: auto;
  padding: 5px 20px;

  font-size: calc(0.5rem + 0.5vw);
  color: #291044;
}

.questionEditContainer .body .centerPanel .imgContainer .actions .inputContainer .inputIframe::placeholder {
  color: #662D91;
}

.questionEditContainer .body .centerPanel .imgContainer .actions .inputContainer .inputIframe:focus {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 1px solid #662D91;
}

/* ------------------------------------ */

.questionEditContainer .body .centerPanel .questionCont {
  width: 80%;

  margin: 10px auto auto auto;

  display: flex;
  flex-flow: column;
}

.answerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(223, 223, 223);
  color: rgb(11, 194, 27);
  cursor: pointer;
  margin-right: 1vw;
}

.answerRight h1 {
  margin: 0;
  font-size: 3vh;
}

.answerOption {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 1vw;
}

.answerOption h1 {
  margin: 0;
  font-size: 3vh;
}

.questionEditContainer .body .centerPanel .questionCont .questionTitle {
  height: 4vw;
  max-width: 90%;
  min-width: 90%;
  min-height: 20vw;
  max-height: 40vw;

  border: none;
  outline: none;
  border-radius: 20px;

  text-align: center;
  font-size: 3vw;
  font-weight: 600;
  color: #662D91;

  margin: auto !important;
  padding: 1% 5%;

  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: #291044 transparent;
}

.questionEditContainer .body .centerPanel .questionCont .questionTitle::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.questionEditContainer .body .centerPanel .questionCont .questionTitle::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.questionEditContainer .body .centerPanel .questionCont .questionTitle::-webkit-scrollbar-thumb {
  background: #291044;
  border-radius: 10px;
}
.questionEditContainer .body .centerPanel .questionCont .questionTitle::-webkit-scrollbar-thumb:hover {
  background: rgb(112,52,187);
}

.questionEditContainer .body .centerPanel .questionCont .questionTitle::-webkit-resizer {
  background: #291044;
  border-radius: 20px 20px 0px 20px;
  width: 6px; height: 6px;
}

.questionEditContainer .body .centerPanel .questionCont .questionTitle::-webkit-scrollbar-corner {
  background-color: transparent;
  width: 6px; height: 6px;
}

.questionEditContainer .body .centerPanel .questionCont .questionTitle::placeholder {
  color: #662D9133;
}

/* ---------------------------- */

.questionEditContainer .body .centerPanel .questionCont .answersCont {
  width: 100%;

  margin: auto auto 20px auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer {
  width: 45%;
  min-width: 250px;
  max-height: 8vh;

  margin: 5px;

  border-radius: 15px;
  border: 1px solid #FFF;
  background-color: white;
  padding: 0;

  display: flex;
  flex-flow: row;
  align-items: center;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer img {
  width: 8%;
  max-height: 30px;
  cursor: pointer;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer button {
  background-color: transparent;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput {
  height: 3vw;
  width: 80%;
  min-height: 30px;
  max-height: 5vh;

  border: none;
  outline: none;
  border-radius: 18px 18px;

  margin: auto;
  padding: 0% 5%;

  text-align: center;
  font-size: calc(0.5rem + 1vw);
  font-weight: 600;
  color: #662D91;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::-webkit-scrollbar-thumb {
  background: #291044;
  border-radius: 10px;
}
.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::-webkit-scrollbar-thumb:hover {
  background: rgb(112,52,187);
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::-webkit-resizer {
  background: #291044;
  border-radius: 20px 20px 0px 20px;
  width: 6px; height: 6px;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::-webkit-scrollbar-corner {
  background-color: transparent;
  width: 6px; height: 6px;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .answerInput::placeholder {
  color: #662D9133;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: auto;

  background: rgb(112,52,187);
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(39,156,239,1) 100%);

  border-radius: 0px 0px 20px 20px;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .containerForm {
  position: relative;

  margin: auto auto auto 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .containerForm .inputFile {
  padding: 5px 20px;
  margin: auto;

  border: none;
  outline: none;
  border-radius: 5px;

  cursor: pointer;

  font-size: 1.2vw;
  color: #FFF;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .containerForm .inputFile:hover {
  background: #FFF0;
  font-weight: 500;

  border-radius: 0px 0px 0px 18px;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .containerForm .worng {
  margin: auto;
  padding: 5px 20px;

  border: none;
  outline: none;
  border-radius: 5px;

  cursor: pointer;

  font-size: 1.2vw;
  font-weight: 500;
  letter-spacing: -1px;
  color: #ffe2ef;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .correctBtn {
  border: none;
  outline: none;
  border-radius: 200px;

  width: 20px;
  min-width: 20px;
  height: 20px;

  margin: auto;

  background-color: #FFF;
  color: #EEE;

  cursor: pointer;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .selected {
  background-color: #7AC943;
  color: #FFF;
}

.questionEditContainer .body .centerPanel .questionCont .answersCont .answer .actions .remove {
  border: none;
  outline: none;
  border-radius: 0px 0px 20px 0px;

  width: 30px;
  height: 30px;

  margin: auto 0 auto auto;

  background-color: #FF0074;
  color: #EEE;

  cursor: pointer;
}

/* ------------------------------------------- */

.questionEditContainer .body .rightPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 18%;
  height: 90%;
  min-width: 150px;

  background-color: #FFF;
  border-radius: 20px;

  margin: 20px auto;

  display: flex;
}

.questionEditContainer .body .rightPanel .rightPanelOption{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  justify-content: center;
  margin-bottom: 2vh;
  text-align: center;
}

.questionEditContainer .body .rightPanel .rightPanelOption input{
  max-width: 90%;
  padding: 1vh;
  outline: none;
  border-radius: 12px;
  border: 1px solid #662d91;
}

.questionEditContainer .body .rightPanel button {
  min-width: 80%;
  padding: 1vh;
  background-color: white;
  margin-bottom: 2vh;
  border: 1px solid #662d91;
  border-radius: 12px;
}

.questionEditContainer .body .rightPanel button:hover {
  color: white;
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

@media all and (max-width: 800px), (orientation: portrait) {
  .questionEditContainer .body {
    width: 98vw;
    height: 100%;
    flex-direction: column;
  }
  .questionEditContainer .body .centerPanel {
    width: 100%;
  
    border-radius: 20px;
  
    margin: 10px 0px;
  
    display: flex;
    flex-flow: column;
  }
  .questionEditContainer .body .centerPanel .imgContainer .iframeCont {
    width: 70vw;
    height: 50vw;
  
    margin: 10px auto;
  
    display: flex;
  }
  .questionEditContainer .body .centerPanel .imgContainer .actions .inputContainer .inputIframe {
    width: 100%;
  
    border: none;
    outline: none;
    background-color: transparent;
  
    margin: auto;
    padding: 5px 20px;
  
    color: #291044;
  }
  .questionEditContainer .body .centerPanel .questionCont .questionTitle {
    height: 4vw;
    max-width: 90%;
    min-width: 90%;
    min-height: 4vw;
    max-height: 200px;
  
    border: none;
    outline: none;
    border-radius: 20px;
  
    text-align: center;
    font-size: calc(0.5rem + 1vw);
    font-weight: 600;
    color: #662D91;
  
    margin: auto !important;
    padding: 1% 5%;
  
    overflow: auto;
  
    scrollbar-width: thin;
    scrollbar-color: #291044 transparent;
  }
  .questionEditContainer .body .rightPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    height: 90%;
    min-width: 150px;
  
    background-color: #FFF;
    border-radius: 20px;
  
    margin: 20px auto;
  
    display: flex;
  }
}
