.TriviaEditContainer {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../media/BackgroundLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TriviaEditHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
}

.TriviaEditHeader img {
  height: 6vh;
  margin-left: 5vw;
}

.TriviaEditHeaderRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
}

.TriviaEditHeaderRight img {
  margin-left: 0vw;
}

.TriviaEditContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 70vh;
}

.TriviaEditContentHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TriviaEditContentHeader div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.TriviaEditName {
  min-height: 5vh;
  font-size: 3vh;
  text-align: center;
  border: purple 1px solid;
  outline: none;
}

.TriviaEditContentHeader h3 {
  flex: 1;
}

.TriviaEditContentHeader .TriviaEditShare {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.TriviaEditContentHeader .TriviaEditShare h3 {
  margin-left: 1vw;
  padding: 0;
}

.TriviaEditPanel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 90vw;
}

.TriviaEditOption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.TriviaEditQuestion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.TriviaEditQuestionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TriviaEditQuestionButtons img {
  margin-left: 1vw;
  max-height: 40px;
  cursor: pointer;
}

.TriviaEditConfig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.TriviaEditConfig div {
  cursor: pointer;
}

.TriviaEditConfig div:hover {
  color: purple;
}

.TriviaEditButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2vh;
}

.TriviaEditButtons button {
  border: 1px solid rgb(178, 116, 230);
  margin-left: 2vw;
  padding: 2vh 3.5vh;
  font-size: 2.5vh;
}

.TriviaEditButtons button:hover {
  color: white;
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

.TriviaEditFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
}

.TriviaEditFooter img {
  height: 6vh;
  margin-right: 5vw;
}

#share {
  font-weight: bold;
  position: relative;
}
#share:before {
  content: ".";
  font-size: 2.2em;
  position: absolute;
  bottom: -2.5px;
  left: -4px;
}
#share:after {
  content: ":";
  font-size: 2em;
  position: absolute;
  bottom: -6px;
  right: -7px;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .TriviaEditPanel {
    flex-direction: column;
  }
  .TriviaEditContentHeader {
    text-align: center;
   }
   .TriviaEditOption {
     width: 90%;
   }
}
