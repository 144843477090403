.RegisterContainer {
  width: 100vw;
  min-height: 100vh;
}

.RegisterHeader {
  display: flex;
  flex-direction: row;
  padding-left: 25vw;
}

.RegisterHeader img {
  max-height: 15vh;
}

.RegisterContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 80vh;
}

.RegisterForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  font-weight: 700;
  min-width: 30vw;
}

.RegisterFormCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 5px 25px;
  border-radius: 10px;
  font-weight: 700;
}

.RegisterFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.RegisterFormInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.RegisterFormInput input, .RegisterFormInput select {
  border: solid rgba(112, 52, 187, 1) 1px;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
  font-weight: 700;
}

.RegisterOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RegisterOptionButton {
  border-radius: 10px;
  padding: 15px;
  border: 1px solid white;
  margin-bottom: 15px;
}

.RegisterOptionButton:hover {
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
  color: white;
}

.RegisterFooterImg {
  position: absolute;
  max-height: 10vh;
  bottom: 1vh;
  right: 1vw;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .RegisterHeader {
    padding-left: 0px;
    justify-content: center;
  }

  .RegisterContent {
    flex-direction: column;
  }

  .RegisterFormContainer {
    margin-bottom: 30px;
  }

}
