.ManageTriviaContainer {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../media/BackgroundLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ManageTriviaHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.ManageTriviaHeader img {
  width: 15vw;
  height: calc(15vw / 5.2);
  margin: 10px auto 10px 5%;
}

.ManageTriviaContent {
  width: 100%;
  height: 100%;
  min-height: 70vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ManageTriviaPin {
  font-size: calc(1rem + 1vw);
  font-weight: 600;
}

.ManageTriviaHeaderRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
}

.ManageTriviaHeaderRight img {
  margin-left: 0vw;
}

.ManageTriviaContentHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ManageTriviaContentHeader div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ManageTriviaName {
  min-height: 5vh;
  text-align: center;
  outline: none;
}

.ManageTriviaContentHeader h3 {
  flex: 1;
}

.ManageTriviaContentHeader .ManageTriviaShare {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ManageTriviaContentHeader .ManageTriviaShare h3 {
  margin-left: 1vw;
  padding: 0;
}

.ManageTriviaPanel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 90vw;
  min-height: max-content;
}

.ManageTriviaOption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 45%;
  width: 50vw;
  max-height: 40vh;
}

.ManageTriviaConnected, .ManageTriviaQuestions {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 40vh;
  padding: 2vh;
  width: 100%;
}

.ManageTriviaQuestion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ManageTriviaQuestion .showContent {
  display: flex;
  flex-flow: row;
}

.ManageTriviaQuestionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ManageTriviaQuestionButtons img {
  margin-left: 1vw;
  max-height: 40px;
  cursor: pointer;
}

.ManageTriviaConfig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.ManageTriviaConfig div {
  cursor: pointer;
}

.ManageTriviaConfig div:hover {
  color: purple;
}

.ManageTriviaButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ManageTriviaButtons button {
  border: 1px solid rgb(178, 116, 230);
  margin-left: 2vw;
  margin-top: 2vw;
  padding: 2vh 3.5vh;
  font-size: calc(0.5rem + .7vw);
}

.ManageTriviaButtons button:hover {
  color: white;
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

.ManageTriviaFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
}

.ManageTriviaFooter img {
  height: 6vh;
  margin-right: 5vw;
}

#share {
  font-weight: bold;
  position: relative;
}
#share:before {
  content: ".";
  font-size: 2.2em;
  position: absolute;
  bottom: -2.5px;
  left: -4px;
}
#share:after {
  content: ":";
  font-size: 2em;
  position: absolute;
  bottom: -6px;
  right: -7px;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .ManageTriviaPanel {
    flex-direction: column;
  }
  .ManageTriviaOption {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 45%;
    width: 90vw;
    max-height: 40vh;
  }
  .ManageTriviaConnected, .ManageTriviaQuestions {
    max-height: 10vh;
    width: 80vw;
  }
  .ManageTriviaContentHeader {
    text-align: center;
   }
   .ManageTriviaOption {
     width: 90%;
   }
}
