.HomeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.HomeContainer .gamesCont {
  display: flex;
  flex-flow: column;

  margin: auto;
}

.HomeContainer .gamesCont button {
  margin: 10px auto;

  padding: 5vh;
}

.HomeContainer img {
  max-width: 50vw;
  max-height: 70vh;
}

.HomeContainer button {
  background: transparent;
  border: 0;
  font-size: 2.5vh;
  padding: 20vh 5vw;

  margin: auto;

  transition: .15s;
}

.HomeContainer button:hover {
  transform: scale(1.04);
}

@media (orientation: portrait) {
  .HomeContainer {
    flex-direction: column;
  }
  .HomeContainer button {
    background: transparent;
    border: 0;
    font-size: 2.5vh;
    padding: 8vh 5vw;
  }
  .HomeContainer img {
    max-width: 80vw;
    max-height: 50vh;
  }
}