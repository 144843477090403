.AvailableContainer {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../media/BackgroundLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AvailableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
}

.AvailableHeader img {
  height: 6vh;
  margin-left: 5vw;
}

.AvailableHeaderRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
}

.AvailableHeaderRight img {
  margin-left: 0vw;
}

.AvailableContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 70vh;
}

.AvailableTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  width: 100%;
  background-color: tomato;
}

.AvailableTrivias {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 50vh;
}

.AvailableItem button {
  border: 0;
  padding: 5vh;
  font-size: 2vh;
  margin: 1vh;
}

.AvailableHeaderRightItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 1vw;
  cursor: pointer;
  text-align: center;
}

.AvailableItem button:hover {
  color: white;
  background: linear-gradient(90deg, rgba(112, 52, 187, 1) 0%, rgba(81, 97, 209, 1) 79%, rgba(39, 156, 239, 1) 100%);
}

.AvailableBackButton {
  border: 1px solid rgb(178, 116, 230);
  margin-left: 2vw;
  padding: 2vh 3.5vh;
  font-size: 2.5vh;
}

.AvailableBackButton:hover {
  color: white;
  background: linear-gradient(90deg, rgba(112, 52, 187, 1) 0%, rgba(81, 97, 209, 1) 79%, rgba(39, 156, 239, 1) 100%);
}

.AvailableFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
}

.AvailableFooter img {
  height: 6vh;
  margin-right: 5vw;
}

.AvailableDataContent {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  border: 4px solid rgb(112, 52, 187);
  padding: 4vh;
}

.AvailableDataContent button {
  margin-top: 2vh;
  font-size: 2vh;
  border: 0;
  background-color: transparent;
  padding: 0;
}

@media (orientation: portrait) {
  .AvailableContent {
    flex-direction: column;
    min-height: 65vh;
  }
  .AvailableOption {
    margin-bottom: 2vh;
  }
  .AvailableOption img {
    max-height: 10vh;
    margin-bottom: 2vh;
  }
}