.DashBoardContainer {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../media/BackgroundLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DashBoardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
}

.DashBoardHeader img {
  height: 6vh;
  margin-left: 5vw;
}

.DashBoardHeaderRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DashBoardHeaderRight img {
  margin-left: 0vw;
}

.DashBoardHeaderRightItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 1vw;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.DashBoardHeaderRightItem img {
  margin: 0 !important;
}

.DashBoardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DashBoardButton {
  border: 0px;
  padding: 4vh 8vh;
  margin-bottom: 4vh;
}

.DashBoardButton:hover {
  color: white;
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

.DashBoardFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
}

.DashBoardFooter img {
  height: 6vh;
  margin-right: 5vw;
}