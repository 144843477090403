@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

img {
  object-fit: contain;
}

h1 {
  font-size: calc(1rem + 2vw);
  line-height: calc(1rem + 2vw);
  margin: calc(0.5rem + .1vw) 0px;
}

h2 {
  font-size: calc(1rem + .8vw);
  margin: calc(0.5rem + 0.5vw) 0px;
}

p {
  margin: calc(0.8vw) 0px;
}

#root {
  height: 100vh;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=checkbox] {
  transform: scale(1.4);
  margin-right: 8px;
}

code {
  font-family: -apple-system, 'Montserrat', sans-serif;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #FFF0;
}
*::-webkit-scrollbar-thumb {
  background: #662d91;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #291044;
}

.linearGradientBackgound1 {
  background: rgb(112,52,187);
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

#root .embebed {
  background: #0F1F67;
  background: linear-gradient(90deg, #002836 0%, #003B4E 100%);
}

.whiteBackground {
  background-color: white;
}

.purpleBackground {
  background: #291044;
}

.purpleColor {
  color: #662d91;
}

.whiteColor {
  color: white;
}

.redColor {
  color: red;
}

.blackColor {
  color: white;
}

.noBold {
  font-weight: 300;
}

.bold {
 font-weight: 900;
}

.semiBold {
  font-weight: 700;
}

button {
  cursor: pointer;
  outline: none;
}


input {
  outline: none;
}

body.swal2-toast-shown .swal2-container {
  height: 100px;
}