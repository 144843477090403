.CodeContainer {
  width: 100vw;
  min-height: 100vh;
  color: #003B4E;
}

.CodeHeader {
  display: flex;
  flex-direction: row;
  padding-left: 25vw;
}

.CodeHeader img {
  max-height: 15vh;
  cursor: pointer;
}

.CodeContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 80vh;
}

.CodeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  font-weight: 700;
  min-width: 30vw;
}

.CodeFormCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 5px 25px;
  border-radius: 10px;
  font-weight: 700;
}

.CodeFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.CodeFormInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.CodeFormInput input, .CodeFormInput select {
  border: solid #003B4E 1px;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
  font-weight: 700;
}


.CodeOptionButton {
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid white;

  margin: 10px 0;
}

.registerCodeOption {
  position: absolute;
  bottom: 1%;
  left: 50%;
  min-width: 200px;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  outline: none;
  margin: auto;
  background-color: transparent;
  color: #FFF;
  transform: translate(-50%, 0);
}

/* .CodeOptionButton:hover,
.registerCodeOption:hover {
  background: linear-gradient(90deg, #003B4E 0%, #002836 100%);
  color: white;
} */

.CodeOptionButton:hover,
.registerCodeOption:hover {
  background: linear-gradient(90deg, #6a24b9 0%, #0097d3 100%);
  color: white;
}

.CodeLink {
  margin-top: 15px;
  color: white;
  text-decoration: none;
  text-align: center;
}

.CodeFooterImg {
  position: absolute;
  max-height: 10vw;
  bottom: 1vh;
  right: 1vw;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .CodeHeader {
    padding-left: 0px;
    justify-content: center;
  }

  .CodeContent {
    flex-direction: column;
  }

  .CodeFormContainer {
    margin-bottom: 30px;
  }

}

@media screen and (max-height: 550px) and (max-width: 540px) {
  .CodeHeader img {
    max-height: 10vh;
    cursor: pointer;
  }

  .CodeFormContainer .RegisterFormCheckbox {
    margin-top: 10px;
  }

  .RegisterFormCheckbox label {
    font-size: 14px;
  }

  form h2 {
    margin: 2px auto 2px 5%;
  }

  .CodeContent .registerCodeOption {
    font-size: 14px;
    width: 90%;
  }
}
