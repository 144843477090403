.LoginContainer {
  width: 100vw;
  min-height: 100vh;
}

.LoginHeader {
  display: flex;
  flex-direction: row;
  padding-left: 25vw;
}

.LoginHeader img {
  max-height: 15vh;
  cursor: pointer;
}

.LoginContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 80vh;
}

.LoginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  font-weight: 700;
  min-width: 30vw;
}

.LoginFormCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 5px 25px;
  border-radius: 10px;
  font-weight: 700;
}

.LoginFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.LoginFormInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.LoginFormInput input, .LoginFormInput select {
  border: solid rgba(112, 52, 187, 1) 1px;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
  font-weight: 700;
}

.LoginOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginOptionButton {
  border-radius: 10px;
  padding: 15px;
  border: 1px solid white;
  margin-bottom: 15px;
}

.LoginOptionButton:hover {
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
  color: white;
}

.LoginLink {
  margin-top: 15px;
  color: white;
  text-decoration: none;
  text-align: center;
}

.LoginFooterImg {
  position: absolute;
  max-height: 10vh;
  bottom: 1vh;
  right: 1vw;
}

@media all and (max-width: 800px), (orientation: portrait) {
  .LoginHeader {
    padding-left: 0px;
    justify-content: center;
  }

  .LoginContent {
    flex-direction: column;
  }

  .LoginFormContainer {
    margin-bottom: 30px;
  }

}
