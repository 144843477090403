.OnlineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;

  scrollbar-width: thin;
  scrollbar-color: #662d91 transparent;

  overflow-x: hidden;
  overflow-y: auto;
}

.OnlineContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 65vw;
}

.OnlineTimeLeft {
  display: flex;
  flex-direction: row;
  margin-bottom: 2vh;
}

.OnlineTimeLeft img {
  max-height: 6vh;
}

.OnlineTimeLeftText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1vh;
}

.OnlineTimeLeftText p, .OnlineTimeLeftText h3 {
  margin: 0px;
}

.OnlineIframe {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  background-color: white;
  width: 100%;
  height: 45vw;
  max-height: 25vh;
  border-radius: 15px;
  box-shadow: black 0px 0px 5px;
  margin-bottom: 2vh;
  box-sizing: border-box;
  display: none;
}

.OnlineIframe iframe {
  max-width: 100%;
  max-height: 100%;
}

.OnlineQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: black 0px 0px 5px;
  margin-bottom: 2vh;
  max-height: 50vh;
  overflow-y: auto;
}

.OnlineQuestion h1 {
  font-size: calc(1rem + 1vw) !important;
  color: #333;
  font-weight: 600;
  width: 100%;
}

h1{
  line-height: calc(1rem + 2vw);
  margin: calc(0.5rem + 0.1vw) auto;
  text-align: center;
}

.CodeContent h1{
  width: 20%;
}

.OnlineAnswers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.OnlineAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 48%;
  margin: 1%;
  border-radius: 15px;
  box-shadow: black 0px 0px 5px;
}

.OnlineAnswer h4{
  padding: 0vw 2vw;
  color: #333;
  font-weight: 500;
}



.OnlineAnswer:hover {
  cursor: pointer;
  background: linear-gradient(90deg, rgba(112, 52, 187, 1) 0%, rgba(81, 97, 209, 1) 79%, rgba(39, 156, 239, 1) 100%);
  color: white;
}

.OnlineCorrectAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 41%;
  margin: 1%;
  border-radius: 15px;
  box-shadow: black 0px 0px 5px;
  background: linear-gradient(90deg, rgba(47, 185, 94, 1) 0%, rgba(43, 233, 0, 1) 100%);
  color: white;
  padding: 0vw 2vw;
}

.OnlineWrongAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 41%;
  margin: 1%;
  border-radius: 15px;
  box-shadow: black 0px 0px 5px;
  background: linear-gradient(90deg, rgba(255, 60, 21, 1) 0%, rgba(170, 33, 0, 1) 100%);
  color: white;
  padding: 0vw 2vw;
}

.OnlineContent .OnlineWrongAnswer .OnlineCheck{
  display:none;
}

.OnlineContent .OnlineCorrectAnswer .OnlineCheck{
  display:none;
}

.OnlineCorrectAnswer .OnlineCheck {
  position: absolute;
  display: flex;
  transform: translate(14vw);
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  height: 3vh;
  width: 3vh;
  color: rgba(43, 233, 0, 1);
}

.OnlineWrongAnswer .OnlineCheck {
  position: absolute;
  transform: translate(15vw);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  height: 3vh;
  width: 3vh;
  color: rgba(170, 33, 0, 1);
}

.OnlineButton {
  border: 0px;
  border-radius: 10px;
  background-color: rgba(252, 252, 252, 0.048);
  color: white;
  box-shadow: black 0px 0px 2px;
  padding: 2vh 6vh;
  margin-bottom: 2vh;
  margin-top: 2vh;
  font-size: 2.5vh;
}

.OnlineButton:hover {
  background-color: rgba(252, 252, 252, 0.281);
}

.OnlineImgHeader {
  position: absolute;
  top: 1vh;
  left: 1vw;
  max-height: 15vh;
  max-width: 17vw;
}

.OnlineImgFooter {
  position: fixed;
  max-height: 12vh;
  top: 86vh;
  right: 1vw;
  max-width: 17vw;
}

.timeToThinkContainer {
  height: 4vw;
}

.timeToThink {
  animation: slidein 1s infinite;
}


@keyframes slidein {
  from {
    font-size: 1.5vw;
  }
  to {
    font-size: 3vw;
  }
}

@media all and (max-width: 800px), (orientation: portrait) {
  .OnlineContainer {
    padding-top: 15vh;
    min-height: 90vh;
    max-height: 90vh;
    justify-content: flex-start;
  }
  .OnlineContent {
    width: 95vw;
  }
  .OnlineImgHeader {
    position: absolute;
    top: 2vw;
    left: 2vw;
    max-height: 15vh;
    max-width: 100vw;
  }
  .OnlineAnswers {
    flex-direction: column;
  }
  .OnlineAnswer {
    width: 94%;
  }
  .OnlineCorrectAnswer {
    width: 90%;
  }
  .OnlineWrongAnswer {
    width: 90%;
  }
  .OnlineWrongAnswer .OnlineCheck {
    position: absolute;
    transform: translate(10vw);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    height: 3vh;
    width: 3vh;
    color: rgba(170, 33, 0, 1);
  }
  .OnlineCorrectAnswer .OnlineCheck {
    position: absolute;
    transform: translate(37vw);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    height: 4vh;
    width: 4vh;
    color: rgba(43, 233, 0, 1);
  }
  .OnlineImgFooter {
    position: fixed;
    max-height: 10vh;
    top: 90vh;
    right: 1vw;
    max-width: 17vw;
  }
}