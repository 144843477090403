.RankingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow-y: auto;
  padding: 5vh 0vh;
}

.RankingContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 75vw;
  height: max-content;
  padding: 5vw;
  background-color: rgba(255, 255, 255, 0.356);
  border-radius: 20px;
  text-align: center;
  font-size: 3vh;
  z-index: 2000;
}

.RankingTitle {
  margin: 0px 0px 20px 0px;
}

.RankingContent h2 {
  margin: 0;
}

.RankingContent::before {
  z-index: -1;
  position: absolute;
  content: "";
  width: 75vw;
  height: 70vh;
  background-image: url('../../../media/BackgroundLogo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: brightness(0);
}

.RankingPlaces {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 60vh;
}

.RankingPlace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 200px;
  width: 30vw;
  margin: 0% 3%;
}

.RankingPlace img {
  width: 80%;
  max-height: 30vh;
  max-width: 30vh;
}

.RankingPlace div {
  padding: 2%;
  height: 96%;
  width: 100%;
}

.secondPlace div {
  background-color: silver;
  background: linear-gradient(90deg, #b8b7b7 0%, #a3a3a5 75%);
}

.firstPlace div {
  background-color: gold;
  background: linear-gradient(90deg, #ffc655 0%, #f0b803 75%);
}

.thirdPlace div {
  background-color: chocolate;
  background: linear-gradient(90deg, #6b3916 0%, #a66717 75%);
}

.secondPlace, .thirdPlace {
  height: 80%;
}

.firstPlace {
  height: 100%;
}

.RankingList {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin-top: 50px;
}

.RankingListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.RankingListItem h4 {
  width: 10%;
  font-size: calc(1rem + 1vw);
  margin: 0;
}

.RankingListItem div {
  display: flex;
  align-items: center;
  padding-left: 2%;
  background-color: white;
  border-radius: 10px;
  margin-left: 2vw;
  width: 90%;
}

.RankingListItem div p {
  margin: 0px;
  padding: 10px;
  width: 33%;
  text-align: center;
  word-break: break-all;
}

.RankingImgFooter {
  position: fixed;
  max-height: 12vh;
  top: 86vh;
  right: 1vw;
  max-width: 17vw;
}

.RankingBackButton {
  position: fixed;
  border: 1px solid rgb(178, 116, 230);
  margin-left: 2vw;
  padding: 1vh 2.5vh;
  font-size: 2.5vh;
  top: 3vh;
  right: 2vw;
  z-index: 9999;
}

.RankingBackButton:hover{ 
  color: white;
  background: linear-gradient(90deg, rgba(112,52,187,1) 0%, rgba(81,97,209,1) 79%, rgba(39,156,239,1) 100%);
}

@media all and (max-width: 800px), (orientation: portrait) {
  .RankingContainer {
    padding-top: 10vh;
    min-height: 90vh;
    justify-content: flex-start;
  }
  .RankingContent::before {
    position: absolute;
    content: "";
    width: 75vw;
    height: 70vh;
    background-image: url('../../../media/BackgroundLogo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .RankingContent {
    width: 92vw;
    font-size: 2.5vh;
    padding: 1vw;
  }
  .RankingListItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  
  .RankingListItem h4 {
    width: 100%;
    font-size: 25px;
    margin: 0;
  }
  
  .RankingListItem div {
    display: flex;
    padding-left: 2%;
    background-color: white;
    border-radius: 10px;
    margin-left: 2vw;
    width: 100%;
  }
  
  .RankingListItem div p {
    margin: 0px;
    padding: 10px;
  }
  .RankingImgFooter {
    position: fixed;
    max-height: 10vh;
    top: 90vh;
    right: 1vw;
    max-width: 17vw;
  }
}