
.gameCont {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../images/imageBG.png');

  overflow: hidden;
  .gameContainer {
    width: 50vw;
    height: 50vw;
    max-width: 750px;
    max-height: 95vh;
    margin: 20px auto;
    border-radius: 10px;
    background-color: #CCC0;
    display: flex;
    flex-flow: column;

    h1, h2 {
      width: 100%;

      text-align: center;
      color: #FFF;
      font-size: 22px;
      line-height: 22px;
    }
    h2 {
      font-size: 16px;
      margin: auto;
      span {
        color: #2931fa;
      }
    }
    .playground {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      perspective: 1000px;
      -webkit-perspective: 1000px;
      margin: auto;
      overflow-y: auto;
      overflow-x: hidden;
      .card {
        width: 20%;
        height: 20%;
        user-select: none;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        margin: 1%;
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
        div {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          transition: 0.6s;
          background: #FFF3;
          display: flex;
        }
        .front {
          font-size: 20px;
          line-height: 120px;
          cursor: pointer;
          color: darken(#f4f5f7,20%);
          display:flex;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          //background: -webkit-linear-gradient(to top, #FFFFFF, #ECE9E6); 
          //background: linear-gradient(to top, #FFFFFF, #ECE9E6);
          .frontCardImg {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 120%;
            height: 120%;

            margin: auto;

            transform: translate(-50%, -50%);

            z-index: 1000;
            img {
              width: 100%;
              height: 100%;

              margin: auto;

              opacity: .8;
            }
          }
        }
        .back {
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          .backCardImg {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 100%;
            height: 100%;

            margin: auto;

            transform: translate(-50%, -50%);
            z-index: 1001;
            img {
              width: 100%;
              height: 100%;

              margin: auto;
            }
          }
        }
        &.opened {
          transform: rotateY(180deg);
          -webkit-transform: rotateY(180deg);
          .frontCardImg {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            display: none;
          }
        }
        &.matched {
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
          .back {
            box-shadow: 0 0 0 2px rgba(#000,.05) inset;
            animation: selected .8s 0s ease 1;
            animation-fill-mode: both;
            opacity: .2;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .gameContainer {
      width: 70vw;
      height: 70vh;
    }
  }

  @media screen and (max-width: 640px) {
    .gameContainer {
      width: 90vw;
      height: 70vh;
    }
  }

  @media screen and (max-width: 990px) and (max-height: 640px) {
    .gameContainer {
      width: 90vw;
      height: 90vh;
    }
  }
}

@keyframes selected {
  0% {
    opacity:.2;
  }
  30% {
    opacity:.5;
  }
  50% {
    opacity:.9;
  }
  70% {
    opacity:.2;
  }
  100% {
    opacity:.3;
  }
}